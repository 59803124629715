import React, {useState, useEffect} from 'react';
import axios from 'axios';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";

function ChangeEmailConfirm(props) {    
    const params = QueryString.parse(props.location.search);

    const [state , setState] = useState({        
        loading: false,
        success: false       
    })

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => {

        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={params: {
            "email":params.email,
            "token":params.t
            }
        }
        axios.post(API_BASE_URL+'/account/emailverification', null, payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        performSuccess();
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
       
        
    }
    const performSuccess = () => {                        
        setState(prevState => ({
            ...prevState,
            'success' : true
        }));
    }
  return(
    
        <div className="hv-center">   
            {state.loading ? <LoadingSpinner /> : <div/> }
            {state.success ? 
                <div className="hv-center">               
                    <h4>Email Confirmed.</h4>                    
                    <h4>Please login with your new email.</h4>
                </div>
            : <div/> }                                      
        </div>
    ) 
}

export default withRouter(ChangeEmailConfirm);