import React from 'react';
import {API_BASE_URL} from '../../constants/apiConstants';
function AppHeader() {
    var env = "";
    if(process.env.NODE_ENV === "development")
        env = "env:"  + process.env.NODE_ENV + "," + API_BASE_URL; 

    return(
        <div className="row col-12 d-flex justify-content-center">
            {env}
        </div>                      
    )
}
export default AppHeader;