import React, {useState} from 'react';
import './App.css';
import Header from './components/Header/Header';
import AppHeader from './components/Header/AppHeader';
import Login from './components/Account/Login/Login';
import Registration from './components/Account/Registration/Registration';
import RegistrationSuccess from './components/Account/Registration/RegistrationSuccess';
import RegistrationActivate from './components/Account/Registration/RegistrationActivate';
import Account from './components/Account/Account';
import User from './components/Account/User';
import ChangeEmail from './components/Account/ChangeEmail/ChangeEmail';
import ChangeEmailConfirm from './components/Account/ChangeEmail/ChangeEmailConfirm';
import ForgotPassword from './components/Account/ForgotPassword/ForgotPassword';
import ChangePassword from './components/Account/ChangePassword/ChangePassword';
import ForgotPasswordSuccess from './components/Account/ForgotPassword/ForgotPasswordSuccess';
import ForgotPasswordReset from './components/Account/ForgotPassword/ForgotPasswordReset';
import Contests from './components/Contests/Contests';
import ContactUs from './components/ContactUs/ContactUs';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AlertComponent from './components/AlertComponent/AlertComponent';
function App() {  
  const [errorMessage, updateErrorMessage] = useState(null);
return (
    <Router>
      
    <div className="App">
        <Switch>
          <Route path="/app">
            <AppHeader/>
          </Route>
          <Route path="/uniwebview/">
            <AppHeader/>
          </Route>
          <Route path="/">
            <Header/>
          </Route>
        </Switch>  

        <div className="container d-flex align-items-center flex-column">
          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage}/>
          <Switch>
            <Route path="/app/newaccount" exact={true}>
              <Registration showError={updateErrorMessage} />
            </Route>
            <Route path="/app/newaccountemail" exact={true}>
              <RegistrationSuccess showError={updateErrorMessage} />
            </Route>
            <Route path="/app/account" exact={true}>
              <Account showError={updateErrorMessage} />
            </Route>
            <Route path="/app/user" exact={true}>
              <User showError={updateErrorMessage} />
            </Route>
            <Route path="/app/changeemail" exact={true}>
              <ChangeEmail showError={updateErrorMessage} />
            </Route>
            <Route path="/app/changepassword" exact={true}>
              <ChangePassword showError={updateErrorMessage} />
            </Route>
            <Route path="/app/forgotpassword" exact={true}>
              <ForgotPassword showError={updateErrorMessage} />
            </Route>
            <Route path="/app/forgotpassword/success" exact={true}>
              <ForgotPasswordSuccess showError={updateErrorMessage} />
            </Route>  
            <Route path="/app/contests" exact={true}>
              <Contests showError={updateErrorMessage} />
            </Route>
            <Route path="/app/contactus" exact={true}>
              <ContactUs showError={updateErrorMessage} />
            </Route>                        
            <Route path="/uniwebview/account/success" component={(props) => { 
                window.location.href = 'uniwebview://newaccount' + props.location.search ; 
                return null;
            }}/>
            <Route path="/uniwebview/user" component={(props) => { 
                window.location.href = 'uniwebview://adduser' + props.location.search ; 
                return null;
            }}/>
            <Route path="/uniwebview/jwt" component={(props) => { 
                window.location.href = 'uniwebview://jwt' + props.location.search ; 
                return null;
            }}/>


            <Route path="/login" exact={true}>
              <Login showError={updateErrorMessage} />
            </Route>
            <Route path="/resetpassword" exact={true}>
              <ForgotPasswordReset showError={updateErrorMessage} />
            </Route>
            <Route path="/activate" exact={true}>
              <RegistrationActivate showError={updateErrorMessage} />
            </Route> 
            <Route path="/changeemailconfirm" exact={true}>
              <ChangeEmailConfirm showError={updateErrorMessage} />
            </Route>                       
          </Switch>          
       </div>
   </div>
  </Router>
  )  
}

export default App;
