import React, {useState} from 'react';
import axios from 'axios';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
function ForgotPassword(props) {
    const [state , setState] = useState({
        email : "",        
        loading: false        
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmitClick = (e) => {        
        e.preventDefault();
        if(validate()){
            sendDetailsToServer();
        }
    }


    const validate = () => {
        let isValid = true;

        if (!state.email) {
          isValid = false;
          props.showError('Please enter a valid email address');
        }
  
        if (typeof state.email !== "undefined") {
        //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            var pattern = new RegExp('^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$');
          if (!pattern.test(state.email)) {
            isValid = false;
            props.showError('Please enter a valid email address');
          }
        }  

        return isValid;
  
    }

    const sendDetailsToServer = () => {
        if(state.email.length) {
            setState(prevState => ({
                ...prevState,
                'loading' : true
            }));

            props.showError(null);
            const payload={params: {
                "email":state.email,
                "password":state.password,
                "firstname":state.firstname,
                "lastname":state.lastname
                }
            }
            axios.post(API_BASE_URL+'/account/resetpasswordemail', null, payload)
                .then(function (response) {

                    setState(prevState => ({
                        ...prevState,
                        'loading' : false
                    }));
                    
                    if(response.status === 200){
                        console.log(response.data);
                        if(response.data.status < 0){
                            console.log(response.data.status);
                            props.showError(response.data.msg);
                            return;
                        }

                        if(response.data){                            
                            redirectToSuccess();
                            props.showError(null);
                        }
                    } else{
                        props.showError("We are experiencing technical difficulties. Please try again later.");
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    setState(prevState => ({
                        ...prevState,
                        'loading' : false
                    }));
                    
                    props.showError('We are experiencing technical difficulties. Please try again later.')    
                });    
        } else {
            props.showError('Please enter a valid email')    
        }
        
    }
    const redirectToSuccess = () => {                
        props.history.push({
            pathname: '/app/forgotpassword/success'
          })        
    }
  return(
    
        <div className="col-12 col-lg-6 mt-2 hv-center">   
    
            {state.loading ? <LoadingSpinner /> : <div/> }        
            
            <form className="form-horizontal">
                <div className="form-group text-left mb-3">

                    <label>
                    Please enter your email address.
                    </label>
                    <br/>
                    <label className="mb-3">
                    You will receive a link to create a new password via email.
                    </label>
                    <input type="email" 
                        className="form-control" 
                        id="email" 
                        placeholder="Enter your email"
                        value={state.email}
                        onChange={handleChange}
                    />
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Submit
                </button>                
            </form>
        </div>
    )
}

export default withRouter(ForgotPassword);