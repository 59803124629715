import React, {useState, useEffect} from 'react';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
function Contests(props) {
    const [state , setState] = useState({                
        email: "",
        loading: false, 
        account: ""
    })

    useEffect(() => {
        handleRefresh();
    }, []);

    const handleRefresh = (e) => {
        let localAccount = localStorage.getItem("account");
        if(localAccount !== null && localAccount !== 'undefined'){
            let account = JSON.parse(localAccount);
            if(account){
                setState(prevState => ({
                    ...prevState,
                    'email' : account.email
                }));

                setState(prevState => ({
                    ...prevState,
                    'account' : account
                }));
            }
        }
    }

  return(
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
            {state.loading ? <LoadingSpinner /> : <div/> }                    

            Coming Soon
        </div>
    )
}

export default withRouter(Contests);