import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './Account.css';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";

function Account(props) {
    const params = QueryString.parse(props.location.search);
    const [state , setState] = useState({                
        email: "",
        token: "",
        username: "",
        firstname: "",
        lastname: "",
        subscribePromo: true,
        loading: false,         
        account: ""
    })

    useEffect(() => {
        handleRefresh();
    }, []);

    const handleRefresh = (e) => {
        let localAccount = localStorage.getItem("account");
        if(localAccount !== null && localAccount !== 'undefined'){
            let account = JSON.parse(localAccount);
            if(account){
                setState(prevState => ({
                    ...prevState,
                    'email' : account.email
                }));
                setState(prevState => ({
                    ...prevState,
                    'token' : account.token
                }));
                setState(prevState => ({
                    ...prevState,
                    'username' : account.username
                }));
                setState(prevState => ({
                    ...prevState,
                    'firstname' : account.firstname
                }));
                setState(prevState => ({
                    ...prevState,
                    'lastname' : account.lastname
                }));

                setState(prevState => ({
                    ...prevState,
                    'account' : account
                }));
                setState(prevState => ({
                    ...prevState,
                    'subscribePromo' : account.subscribePromo
                }));
            }
        }
    }

    const handleChange = (e) => {
        const {id , value} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleCheckbox= (e) => {
        const {id , checked} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : checked
        }))
    }

    const gotoUser = (e) => {
        e.preventDefault();
        props.history.push({
            pathname: '/app/user',
            search: '?email=' + encodeURIComponent(state.email) + '&user=' + e.target.dataset.txt            
          })  
    }

    const gotoChangePassword = (e) => {
        props.history.push({
            pathname: '/app/changepassword',
            search: '?email=' + encodeURIComponent(state.email)
          })  
    }
    const gotoChangeEmail = (e) => {
        props.history.push({
            pathname: '/app/changeemail',
            search: '?email=' + encodeURIComponent(state.email)
          })  
    }
    const gotoChangeMembership = (e) => {
        props.history.push({
            pathname: '/app/changeemail',
            search: '?email=' + encodeURIComponent(state.email)
          })  
    }

    const handleSubmitClick = (e) => {        
        e.preventDefault();
        if(validate()){
            sendRequest();
        }
    }


    const validate = () => {
        let isValid = true;

        if (!state.firstname) {
            isValid = false;
            props.showError('Please enter your first name');
        }
        if (!state.lastname) {
            isValid = false;
            props.showError('Please enter your last name');
        }
        if (!state.username) {
            isValid = false;
            props.showError('Please enter your username');
        }

        return isValid;
  
    }

    const sendRequest = () => {
        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={params: {
            "email":state.email,
            "token":state.token,
            "username":state.username,
            "firstname":state.firstname,
            "lastname":state.lastname,
            "subscribepromo":state.subscribePromo
            }
        }
        axios.put(API_BASE_URL+'/account', null, payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        //handle JWT error
                        if(response.data.status == -100 || 
                            response.data.status == -101){
                            handleJWTError(response.data.status);
                        }
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        handleSuccess(response.data.account);
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);
                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
        
    }
    
    const handleSuccess = (account) => {                
        localStorage.setItem('account', JSON.stringify(account));        
        setState(prevState => ({
            ...prevState,
            'successMessage' : 'Save successful.'
        }))

        if(params.callback == "app"){
            props.history.push({
                pathname: '/uniwebview/user',
                search: '?account=' + encodeURIComponent(JSON.stringify(account))
              })
        }else{     
            props.history.push({
                pathname: '/uniwebview/user',
                search: '?account=' + encodeURIComponent(JSON.stringify(account))
              })   
        }
    }

    const handleJWTError = (status) => {                
        props.history.push({
            pathname: '/uniwebview/jwt',
            search: '?status=' + status
          })        
    }

    const goBack = (e) => {
        props.history.push({
            pathname: '/app/account'
          })      
    }

  return(
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
            {state.loading ? <LoadingSpinner /> : <div/> }                    

            <form className="form-horizontal">            
                <div className="form-group text-left mb-3">
                <label htmlFor="inputEmail1">Email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter email"
                       value={state.email}
                       onChange={handleChange}
                       disabled 
                       readOnly
                />
                <button 
                    type="submit" 
                    className="btn btn-outline-primary"
                    onClick={gotoChangeEmail}
                >Change Email</button>
                </div>
                <div className="form-group text-left mb-3">
                <label htmlFor="inputEmail1">Password</label>
                <br/>
                <button 
                    type="submit" 
                    className="btn btn-outline-primary"
                    onClick={gotoChangePassword}
                >Change Password</button>
                </div> 
                <div className="form-group text-left mb-3">
                <label htmlFor="inputUsername">Username</label>
                <input type="usernmae" 
                       className="form-control" 
                       id="username"  
                       placeholder="Enter username"
                       value={state.username}
                       onChange={handleChange}
                />
                </div> 
                <div className="form-group text-left mb-3">
                <label htmlFor="inputUsername">Firstname</label>
                <input type="firstname" 
                       className="form-control" 
                       id="firstname"  
                       placeholder="Enter First Name"
                       value={state.firstname}
                       onChange={handleChange}                       
                />
                </div> 
                <div className="form-group text-left mb-3">
                <label >Last Name</label>
                <input type="lastname" 
                    className="form-control" 
                    id="lastname" 
                    placeholder="Enter your last name"
                    value={state.lastname}
                    onChange={handleChange}
                />
                </div> 
                <div className="form-check">
                <input className="form-check-input" type="checkbox" id="subscribePromo"  onChange={handleCheckbox} checked={state.subscribePromo}/>
                <label className="form-check-label" htmlFor="flexCheckDefault">Subscribe to stay updated with new products and offers</label>
                </div> 
                <div className="form-group text-left mb-3">
                <button 
                    type="submit" 
                    className="btn btn-outline-primary"
                    onClick={handleSubmitClick}
                >Save</button>
                </div>
                {/* <div className="form-group text-left mb-3">
                <label htmlFor="membership">Membership Subscription: Free</label>
                <br/>
                <button 
                    type="submit" 
                    className="btn btn-outline-primary"
                    onClick={gotoChangeMembership}
                >Upgrade</button>
                </div>                */}
                {/* <div className="form-group text-left mt-3 mb-3">
                    <label htmlFor="inputEmail1">Family Members
                    </label>
                    <br/>
                        <button 
                            type="submit"  
                            className="btn btn-sq btn-lp-r"                            
                            data-txt="0" 
                            onClick={gotoUser}>                            
                            {state.account && state.account.users[0]? 
                                <i data-txt="0" className="fa fa-user fa-4x"></i>:
                                <i data-txt="0" className="fa fa-plus fa-4x  mt-2"></i>}
                            <br/>
                            {state.account && state.account.users[0]?
                                state.account.users[0].firstname:""} 
                        </button>
                        <button 
                            type="submit" 
                            className="btn btn-sq btn-lp-b"
                            data-txt="1"
                            onClick={gotoUser}>
                            {state.account && state.account.users[1]? 
                                <i data-txt="1" className="fa fa-user fa-4x"></i>:
                                <i data-txt="1" className="fa fa-plus fa-4x  mt-2"></i>}
                            <br/>
                            {state.account && state.account.users[1]?
                                state.account.users[1].firstname:""} 
                        </button>
                        <button 
                            type="submit" 
                            className="btn btn-sq btn-lp-y"
                            data-txt="2"
                            onClick={gotoUser}>
                            {state.account && state.account.users[2]? 
                                <i data-txt="2" className="fa fa-user fa-4x"></i>:
                                <i data-txt="2" className="fa fa-plus fa-4x  mt-2"></i>}
                            <br/>
                            {state.account && state.account.users[2]?
                                state.account.users[2].firstname:""} 
                        </button>
                        <button 
                            type="submit" 
                            className="btn btn-sq btn-lp-g"
                            data-txt="3"
                            onClick={gotoUser}>                                
                            {state.account && state.account.users[3]? 
                                <i data-txt="3" className="fa fa-user fa-4x"></i>:
                                <i data-txt="3" className="fa fa-plus fa-4x  mt-2"></i>}
                            <br/>
                            {state.account && state.account.users[3]?
                                state.account.users[3].firstname:""} 
                        </button>                        
                </div>     */}
                <button 
                    type="submit" 
                    id="refresh"
                    className="btn btn-primary invisible"
                    onClick={handleRefresh}
                    
                >
                    Refresh
                </button>                          
            </form>
        </div>
    )
}

export default withRouter(Account);