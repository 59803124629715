import React, {useState, useEffect} from 'react';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";

function User(props) {
    const params = QueryString.parse(props.location.search);
    
    const [state , setState] = useState({                
        email: "",
        token: "",
        tokenExp: "",
        userid: -1,
        firstname: "",
        lastname: "",
        gender: "NA",
        birthday: null,        
        subscribePromo: true,
        loading: false,
        successMessage: null     
    })

    useEffect(() => {
        handleRefresh();
    }, []);


    const handleRefresh = (e) => {
        let localAccount = localStorage.getItem("account");
        if(localAccount !== null && localAccount !== 'undefined'){
            let account = JSON.parse(localAccount);
            
            setState(prevState => ({
                ...prevState,
                'email' : account.email
            }));
            setState(prevState => ({
                ...prevState,
                'token' : account.token
            }));
            setState(prevState => ({
                ...prevState,
                'tokenExp' : account.tokenExp
            }));
            setState(prevState => ({
                ...prevState,
                'subscribePromo' : account.subscribePromo
            }));

            //Populate user data
            let users = account.users;
            let userIndex = params.user;
            if(userIndex == null){
                return;
            }
            let user = users[userIndex];
            if(user == null){
                return;
            }

            setState(prevState => ({
                ...prevState,
                'userid' : user.id
            }));

            setState(prevState => ({
                ...prevState,
                'firstname' : user.firstname
            }));
            setState(prevState => ({
                ...prevState,
                'lastname' : user.lastname
            }));

            if(user.gender != null){
                setState(prevState => ({
                    ...prevState,
                    'gender' : user.gender
                }));
            }

            if(user.birthday != null){
                setState(prevState => ({
                    ...prevState,
                    'birthday' : user.birthday
                }));
            }
        }
    };

    const handleChange = (e) => {
        const {id , value} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleCheckbox= (e) => {
        const {id , checked} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : checked
        }))
    }
    const handleSubmitClick = (e) => {        
        e.preventDefault();        
        if(validate()){
            sendRequest() 
        }
    }
    const deleteUser = (e) => {        
        e.preventDefault();                
        sendDeleteUserRequest(); 
    }

    const validate = () => {
        let isValid = true;

        if (!state.email) {
            isValid = false;
            props.showError('Error');
          }

        if (!state.firstname) {
            isValid = false;
            props.showError('Please enter your first name');
        }

        if (!state.lastname) {
            isValid = false;
            props.showError('Please enter your last name');
        }

        return isValid;
  
    }
  

    const sendRequest = () => {
        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={params: {
            "email":state.email,
            "token":state.token,
            "userid":state.userid,
            "userindex":params.user,
            "firstname":state.firstname,
            "lastname":state.lastname,
            "gender":state.gender,
            "birthday":state.birthday, 
            "subscribepromo":state.subscribePromo
            }
        }
        axios.post(API_BASE_URL+'/account/user', null, payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        //handle JWT error
                        if(response.data.status == -100 || 
                            response.data.status == -101){
                            handleJWTError(response.data.status);
                        }
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        handleSuccess(response.data.account);
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);
                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
        
    }

    const sendDeleteUserRequest = () => {
        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={data: {
            "email":state.email,
            "token":state.token,
            "userid":state.userid
            }
        }
        axios.delete(API_BASE_URL+'/account/user', payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        //handle JWT error
                        if(response.data.status == -100 || 
                            response.data.status == -101){
                            handleJWTError(response.data.status);
                        }
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        handleSuccess(response.data.account);
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);
                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
        
    }
    
    const handleSuccess = (account) => {                
        localStorage.setItem('account', JSON.stringify(account));        
        setState(prevState => ({
            ...prevState,
            'successMessage' : 'Save successful.'
        }))

        if(params.callback == "app"){
            props.history.push({
                pathname: '/uniwebview/user',
                search: '?account=' + encodeURIComponent(JSON.stringify(account))
              })
        }else{
            // props.history.push({
            //     pathname: '/app/account'
            // })      
            props.history.push({
                pathname: '/uniwebview/user',
                search: '?account=' + encodeURIComponent(JSON.stringify(account))
              })   
        }
    }

    const handleJWTError = (status) => {                
        props.history.push({
            pathname: '/uniwebview/jwt',
            search: '?status=' + status
          })        
    }

    const goBack = (e) => {
        props.history.push({
            pathname: '/app/account'
          })      
    }

    function AvatorButton(){
        let userIndex = params.user;
        if(userIndex == 0){
            return  <div className="form-group text-left mb-3">
                    <a href="#" className="btn btn-sq btn-lp-r">
                            <i className="fa fa-user fa-4x"></i><br/> </a>
                    <br/><br/>
                    <h4>Primary User</h4>        
                    </div>
        }else if(userIndex == 1){
            return <a href="#" className="btn btn-sq btn-lp-b">
                            <i className="fa fa-user fa-4x"></i><br/> </a>
        }else if(userIndex == 2){
            return <a href="#" className="btn btn-sq btn-lp-y">
                            <i className="fa fa-user fa-4x"></i><br/> </a>
        }else if(userIndex == 3){
            return <a href="#" className="btn btn-sq btn-lp-g">
                            <i className="fa fa-user fa-4x"></i><br/> </a>
        }
    }

  return(
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
    
            {state.loading ? <LoadingSpinner /> : <div/> }                    

            <form className="form-horizontal">
                <AvatorButton />
                <div className="form-group text-left mb-3">
                    <label>First Name</label>
                    <input type="firstname" 
                        className="form-control" 
                        id="firstname" 
                        placeholder="Enter your first name"
                        value={state.firstname}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group text-left mb-3">
                    <label >Last Name</label>
                    <input type="lastname" 
                        className="form-control" 
                        id="lastname" 
                        placeholder="Enter your last name"
                        value={state.lastname}
                        onChange={handleChange}
                    />
                </div> 

                <div className="form-group text-left mb-3">
                    <label >Gender</label>
                    
                    <select id="gender" class="form-select" 
                    value={state.gender} onChange={handleChange}>
                    <option value="NA">Not Specified</option>
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                    </select>
                </div> 

                <div className="form-group text-left mb-3">                    
                    <label forHTML="birthday">Birthday</label>
                    <input type="date" className="form-control"  id="birthday" name="birthday" 
                        value={state.birthday}
                        onChange={handleChange}/>                                 
                </div>                
                                
                {params.user == 0? 
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="subscribePromo"  onChange={handleCheckbox} checked={state.subscribePromo}/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">Subscribe to stay updated with new products and offers</label>
                    </div>: "" }     
                {params.callback != "app"? 
                <button 
                        type="submit" 
                        className="btn btn-primary"
                        onClick={goBack}
                    >
                        Back
                    </button>:""}                        
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Save
                </button> 
                {params.user != 0 && state.userid != -1? 
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        onClick={deleteUser}
                    >
                        Delete User
                    </button>:""}                                
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>            
        </div>
    )
}

export default withRouter(User);