import React, {useState, useEffect} from 'react';
import axios from 'axios';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string"

function ChangeEmail(props) {
    const params = QueryString.parse(props.location.search);

    const [state , setState] = useState({
        email   : "",
        password : "",
        newemail : "",        
        confirmemail : "",
        loading: false,
        completed:  false        
    })
    
    useEffect(() => {
        let account = JSON.parse(localStorage.getItem("account"));
        if(account){
            setState(prevState => ({
                ...prevState,
                'email' : account.email
            }));
        }
    }, []);

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmitClick = (e) => {        
        e.preventDefault();
        if(validate()){
            sendRequest();
        }
    }


    const validate = () => {
        let isValid = true;

        if (!state.password ) {
            isValid = false;
            props.showError('Please enter password');
        } 
        if(state.newemail !== state.confirmemail) {
            isValid = false;
            props.showError('New email and confirm email do not match');
        }

        if (!params.email) {
            isValid = false;
            props.showError('We are experiencing technical difficulties. Please try again later.');
        }
  
        return isValid;
  
    }

    const sendRequest = () => {

        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={params: {
            "email":params.email,
            "newemail":state.newemail,
            "password":state.password
            }
        }
        axios.post(API_BASE_URL+'/account/email', null, payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        performSuccess();
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
       
        
    }
    const performSuccess = () => {                        
        setState(prevState => ({
            ...prevState,
            'completed' : true
        }));
    }

    const goBack = (e) => {
        props.history.push({
            pathname: '/app/account'
          })      
    }

  return(
    
        <div className="col-12 col-lg-6 mt-2 hv-center">               
            {state.loading ? <LoadingSpinner /> : <div/> }        
            <h1>Change Email</h1>
            <div className="form-group text-left mb-3">
                <label htmlFor="inputEmail1">Current Email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter email"
                       value={state.email}
                       readOnly
                />
                </div>
                {!state.completed ?
                    <div className="form-group text-left mb-3">
                        <label htmlFor="exampleInputPassword1">New Email</label>
                        <input 
                            className="form-control" 
                            id="newemail" 
                            placeholder="New Email"
                            value={state.newemail}
                            onChange={handleChange} 
                        /> 
                    </div>    
                : <div></div>}         
                {!state.completed ?         
                    <div className="form-group text-left mb-3">
                        <label htmlFor="exampleInputPassword1">Confirm New Email</label>
                        <input 
                            className="form-control" 
                            id="confirmemail" 
                            placeholder="Confirm New Email"
                            value={state.confirmemail}
                            onChange={handleChange} 
                        />
                    </div>  
                : <div></div>}                   
            <form className="form-horizontal">
                {!state.completed ?
                    <div className="form-group text-left mb-3">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" 
                            className="form-control" 
                            id="password" 
                            placeholder="Enter Your Password to Change Email"
                            value={state.password}
                            onChange={handleChange} 
                        /> 
                    </div>    
                : <div></div>}                 
                {state.completed ? 
                    <div className="form-group text-left">
                        <br/>
                        <h5> An email has been sent to you.</h5>
                        <h5>Please follow the instruction on email to confirm.</h5>  
                        <br/>                   
                    </div> 
                    : <div></div>}                  
                <button 
                        type="submit" 
                        className="btn btn-primary"
                        onClick={goBack}
                    >
                        Back
                    </button> 
                {!state.completed ?            
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        onClick={handleSubmitClick}
                    >
                        Submit
                    </button>  
                    : <div></div>} 

                                                                       
            </form>
        </div>
    )
}

export default withRouter(ChangeEmail);