import React, {useState} from 'react';
import axios from 'axios';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";

function ForgotPasswordReset(props) {
    const params = QueryString.parse(props.location.search);

    const [state , setState] = useState({
        password : "",        
        confirmPassword : "",
        loading: false,
        completed:  false        
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmitClick = (e) => {        
        e.preventDefault();
        if(validate()){
            sendRequest();
        }
    }


    const validate = () => {
        let isValid = true;

        if (!state.password || !state.confirmPassword) {
            isValid = false;
            props.showError('Please enter a password');
        } 
        if(state.password !== state.confirmPassword) {
            isValid = false;
            props.showError('Password and confirm password do not match');
        }

        if (!params.email || !params.t) {
            isValid = false;
            props.showError('Invalid link. Please try again with the link from email.');
          }
  
        return isValid;
  
    }

    const sendRequest = () => {

        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={params: {
            "email":params.email,
            "token":params.t,
            "password":state.password

            }
        }
        axios.post(API_BASE_URL+'/account/resetpassword', null, payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        performSuccess();
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
       
        
    }
    const performSuccess = () => {                        
        setState(prevState => ({
            ...prevState,
            'completed' : true
        }));
    }
  return(
    
        <div className="col-12 col-lg-6 mt-2 hv-center">               
            {state.loading ? <LoadingSpinner /> : <div/> }        
            <h1>Reset Password</h1>
            <form className="form-horizontal">
                {!state.completed ?
                    <div className="form-group text-left mb-3">
                        <label htmlFor="exampleInputPassword1">Enter Your New Password</label>
                        <input type="password" 
                            className="form-control" 
                            id="password" 
                            placeholder="Password"
                            value={state.password}
                            onChange={handleChange} 
                        /> 
                    </div>    
                : <div></div>}         
                {!state.completed ?         
                    <div className="form-group text-left mb-3">
                        <label htmlFor="exampleInputPassword1">Confirm Password</label>
                        <input type="password" 
                            className="form-control" 
                            id="confirmPassword" 
                            placeholder="Confirm Password"
                            value={state.confirmPassword}
                            onChange={handleChange} 
                        />
                    </div>  
                : <div></div>}   
                {!state.completed ?            
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        onClick={handleSubmitClick}
                    >
                        Submit
                    </button>  
                    : <div></div>} 

                {state.completed ? 
                    <div className="form-group text-left mb-3">
                        <label>Your password has been reset. Login with your new password.</label>                        
                    </div> 
                    : <div></div>} 
            </form>
        </div>
    )
}

export default withRouter(ForgotPasswordReset);