import React, {useState} from 'react';
import axios from 'axios';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
function Login(props) {
    const [state , setState] = useState({
        email : "",
        password : "",
        loading: false, 
        success: false,
        account: ""
    })
    const handleChange = (e) => {
        const {id , value} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const handleSubmitClick = (e) => {        
        e.preventDefault();
        if(validate()){
            sendRequest() 
        }
    }

    const validate = () => {
        let isValid = true;

        if (!state.email) {
          isValid = false;
          props.showError('Please enter a valid email address');
        }
  
        if (typeof state.email !== "undefined") {
            var pattern = new RegExp('^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$');
          if (!pattern.test(state.email)) {
            isValid = false;
            props.showError('Please enter a valid email address');
          }
        }  

        if (!state.password ) {
            isValid = false;
            props.showError('Please enter a password');
        } 
        return isValid;
  
    }
  
  

    const sendRequest = () => {
        if(state.email.length && state.password.length) {
            setState(prevState => ({
                ...prevState,
                'loading' : true
            }));

            props.showError(null);
            const payload={params: {
                "email":state.email,
                "password":state.password,
                }
            }
            axios.post(API_BASE_URL+'/login', null, payload)
                .then(function (response) {

                    setState(prevState => ({
                        ...prevState,
                        'loading' : false
                    }));
                    
                    if(response.status === 200){
                        console.log(response.data);
                        if(response.data.status < 0){
                            console.log(response.data.status);
                            props.showError(response.data.msg);
                            return;
                        }

                        if(response.data){                            
                            performSuccess(response.data.account);
                            props.showError(null);
                        }
                    } else{
                        props.showError("We are experiencing technical difficulties. Please try again later.");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setState(prevState => ({
                        ...prevState,
                        'loading' : false
                    }));
                    props.showError('We are experiencing technical difficulties. Please try again later.')    
                });    
        } else {
            props.showError('Please enter valid username and password')    
        }
        
    }
    const performSuccess = (account) => {        
        setState(prevState => ({
            ...prevState,
            'success' : true
        }));
        setState(prevState => ({
            ...prevState,
            'account' : account
        }));

        localStorage.setItem('account', JSON.stringify(account));

    }
  return(
    
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
    
            {state.loading ? <LoadingSpinner /> : <div/> }   
            {state.success ? <div>Login Success - {state.account.token}</div> : <div/> }        

            <form className="form-horizontal">
             
                <div className="form-group text-left mb-3">
                <label htmlFor="inputEmail1">Email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter email"
                       value={state.email}
                       onChange={handleChange}
                />
                </div>
                <div className="form-group text-left mb-3">
                    <label htmlFor="inputPassword1">Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="password" 
                        placeholder="Password"
                        value={state.password}
                        onChange={handleChange} 
                    />
                </div>          
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Submit
                </button>                
            </form>
        </div>
    )
}

export default withRouter(Login);