import { withRouter } from "react-router-dom";
function ForgotPasswordSuccess(props) {

  return(
    
        <div className="col-12 col-lg-6 mt-2 hv-center">       
            
            <form className="form-horizontal">
                <div className="form-group text-left mb-3">

                    <label>
                    An email has been sent to you. 
                    </label>
                    <br/>
                    <label className="mb-3">
                    Please follow the instruction on email to reset your password.
                    </label>
                    
                </div>             
            </form>
        </div>
    )
}

export default withRouter(ForgotPasswordSuccess);