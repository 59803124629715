import React, {useState} from 'react';
import axios from 'axios';
import './Registration.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
function Registration(props) {
    const [state , setState] = useState({
        email : "",
        password : "",
        confirmPassword : "",
        firstname : "",
        lastname : "",
        username : "",
        // email : "zero+test34@teamzerostudio.com",
        // username:"hilton34",
        // password : "hilton",
        // confirmPassword : "hilton",
        // firstname : "h",
        // lastname : "l",
        
        account : {},        
        subscribePromo : true,
        acceptTerms : true,
        loading: false
    })
    const handleChange = (e) => {
        const {id , value} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleCheckbox= (e) => {
        const {id , checked} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : checked
        }))
    }
    const handleSubmitClick = (e) => {        
        e.preventDefault();
        // redirectToMain();
        if(validate()){
            sendDetailsToServer() 
        }
        // if(state.password === state.confirmPassword) {
        //     sendDetailsToServer()    
        // } else {
        //     props.showError('Passwords do not match');
        // }
    }

    const validate = () => {
        let isValid = true;

        if (!state.firstname) {
            isValid = false;
            props.showError('Please enter your first name');
        }

        if (!state.username) {
            isValid = false;
            props.showError('Please enter your username');
        }

        if (!state.lastname) {
            isValid = false;
            props.showError('Please enter your last name');
        }
        if (!state.username) {
            isValid = false;
            props.showError('Please enter your username');
        }

        if (!state.email) {
          isValid = false;
          props.showError('Please enter a valid email address');
        }
  
        if (typeof state.email !== "undefined") {
        //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            var pattern = new RegExp('^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$');
          if (!pattern.test(state.email)) {
            isValid = false;
            props.showError('Please enter a valid email address');
          }
        }  

        if (!state.password || !state.confirmPassword) {
            isValid = false;
            props.showError('Please enter a password');
        } 
        if(state.password !== state.confirmPassword) {
            isValid = false;
            props.showError('Password and confirm password do not match');
        }

        if(state.acceptTerms !== true){
            isValid = false;
            props.showError('Please accept our terms to proceed');
        }
  
        return isValid;
  
    }
  
  

    const sendDetailsToServer = () => {
        if(state.email.length && state.password.length) {
            setState(prevState => ({
                ...prevState,
                'loading' : true
            }));

            props.showError(null);
            const payload={params: {
                "email":state.email,
                "password":state.password,
                "username":state.username,
                "firstname":state.firstname,
                "lastname":state.lastname, 
                "acceptterms":state.acceptTerms,
                "subscribepromo":state.subscribePromo
                }
            }
            axios.post(API_BASE_URL+'/account', null, payload)
                .then(function (response) {

                    setState(prevState => ({
                        ...prevState,
                        'loading' : false
                    }));
                    
                    if(response.status === 200){
                        console.log(response.data);
                        if(response.data.status < 0){
                            console.log(response.data.status);
                            props.showError(response.data.msg);
                            return;
                        }

                        if(response.data){                            
                            // redirectToMain(response.data.account);
                            handleSuccess(response.data.account);
                            props.showError(null);
                        }
                    } else{
                        props.showError("We are experiencing technical difficulties. Please try again later.");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setState(prevState => ({
                        ...prevState,
                        'loading' : false
                    }));
                    props.showError('We are experiencing technical difficulties. Please try again later.')    
                });    
        } else {
            props.showError('Please enter valid username and password')    
        }
        
    }
    // const redirectToMain = (account) => {        
    //     console.log("redirectToMain");
    //     props.history.push({
    //         pathname: '/uniwebview/account/success',
    //         search: '?email=' + state.email + '&password=' + state.password + '&firstname=' + state.firstname + '&lastname=' + state.lastname + '&account=' + encodeURIComponent(JSON.stringify(account))
    //       })        
    // }

    const handleSuccess = (account) => {                
        localStorage.setItem('account', JSON.stringify(account));        
        setState(prevState => ({
            ...prevState,
            'successMessage' : 'Save successful.'
        }))

        props.history.push({
            pathname: '/app/newaccountemail'
        })          
    }

  return(
    
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
    
            {state.loading ? <LoadingSpinner /> : <div/> }        

            <form className="form-horizontal">

                <div className="form-group text-left mb-3">
                    <label>First Name</label>
                    <input type="firstname" 
                        className="form-control" 
                        id="firstname" 
                        placeholder="Enter your first name"
                        value={state.firstname}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group text-left mb-3">
                    <label >Last Name</label>
                    <input type="lastname" 
                        className="form-control" 
                        id="lastname" 
                        placeholder="Enter your last name"
                        value={state.lastname}
                        onChange={handleChange}
                    />
                </div> 
                <div className="form-group text-left mb-3">
                    <label >Username</label>
                    <input type="username" 
                        className="form-control" 
                        id="username" 
                        placeholder="Enter your username"
                        value={state.username}
                        onChange={handleChange}
                    />
                </div>                
                <div className="form-group text-left mb-3">
                <label htmlFor="inputEmail1">Email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter email"
                       value={state.email}
                       onChange={handleChange}
                />
                </div>
                <div className="form-group text-left mb-3">
                    <label htmlFor="inputPassword1">Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="password" 
                        placeholder="Password"
                        value={state.password}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group text-left mb-3">
                    <label htmlFor="inputPassword1">Confirm Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="confirmPassword" 
                        placeholder="Confirm Password"
                        value={state.confirmPassword}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="acceptTerms"  onChange={handleCheckbox} checked={state.acceptTerms}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">I accept the terms and service</label>
                </div>  
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="subscribePromo"  onChange={handleCheckbox} checked={state.subscribePromo}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Subscribe to stay updated with new products and offers</label>
                </div>                
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Submit
                </button>                
            </form>
        </div>
    )
}

export default withRouter(Registration);