import React, {useState} from 'react';
import axios from 'axios';
import './Registration.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../../constants/apiConstants';
import { withRouter } from "react-router-dom";
function RegistrationSuccess(props) {    

  return(
    
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
            
            <h4>An email has been sent to you.</h4>
            <br/>
            <h4>Please follow the instruction on email to confirm and get started.</h4>
                                        
        </div>
    ) 
}

export default withRouter(RegistrationSuccess);