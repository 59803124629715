import React from 'react';
import {API_BASE_URL} from '../../constants/apiConstants';
function Header() {
    var env = "";
    if(process.env.NODE_ENV === "development")
        env = "env:"  + process.env.NODE_ENV + "," + API_BASE_URL; 

    return(       
                  
        <nav className="navbar navbar-dark">
            <img src="http://w.teamzerostudio.com/logo/teamzero_logo15_80.png"/>           
            <div className="row col-12 d-flex justify-content-center ">               
            {env}
            </div>
        </nav>        
    )
}
export default Header;