import React, {useState, useEffect} from 'react';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {API_BASE_URL} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string";

function ContactUs(props) {
    const params = QueryString.parse(props.location.search);
    const [state , setState] = useState({                
        email: "",
        token: "",
        username: "",
        firstname: "",
        lastname: "",
        message: "",
        loading: false,         
        account: "",
        completed:  false  
    })

    useEffect(() => {
        handleRefresh();
    }, []);

    const handleRefresh = (e) => {
        let localAccount = localStorage.getItem("account");
        if(localAccount !== null && localAccount !== 'undefined'){
            let account = JSON.parse(localAccount);
            if(account){
                setState(prevState => ({
                    ...prevState,
                    'email' : account.email
                }));
                setState(prevState => ({
                    ...prevState,
                    'token' : account.token
                }));
                setState(prevState => ({
                    ...prevState,
                    'username' : account.username
                }));
                setState(prevState => ({
                    ...prevState,
                    'firstname' : account.firstname
                }));
                setState(prevState => ({
                    ...prevState,
                    'lastname' : account.lastname
                }));

                setState(prevState => ({
                    ...prevState,
                    'account' : account
                }));
            }
        }
    }

    const handleChange = (e) => {
        const {id , value} = e.target  
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const gotoUser = (e) => {
        e.preventDefault();
        props.history.push({
            pathname: '/app/user',
            search: '?email=' + encodeURIComponent(state.email) + '&user=' + e.target.dataset.txt            
          })  
    }

    const gotoChangePassword = (e) => {
        props.history.push({
            pathname: '/app/changepassword',
            search: '?email=' + encodeURIComponent(state.email)
          })  
    }
    const gotoChangeEmail = (e) => {
        props.history.push({
            pathname: '/app/changeemail',
            search: '?email=' + encodeURIComponent(state.email)
          })  
    }
    const gotoChangeMembership = (e) => {
        props.history.push({
            pathname: '/app/changeemail',
            search: '?email=' + encodeURIComponent(state.email)
          })  
    }

    const handleSubmitClick = (e) => {        
        e.preventDefault();
        if(validate()){
            sendRequest();
        }
    }


    const validate = () => {
        let isValid = true;

        if (!state.firstname) {
            isValid = false;
            props.showError('Please enter your first name');
        }
        if (!state.lastname) {
            isValid = false;
            props.showError('Please enter your last name');
        }
        if (!state.username) {
            isValid = false;
            props.showError('Please enter your username');
        }

        return isValid;
  
    }

    const sendRequest = () => {
        setState(prevState => ({
            ...prevState,
            'loading' : true
        }));

        props.showError(null);
        const payload={params: {
            "email":state.email,
            "token":state.token,
            "username":state.username,
            "firstname":state.firstname,
            "lastname":state.lastname,
            "message":state.message
            }
        }
        axios.post(API_BASE_URL+'/app/contactus', null, payload)
            .then(function (response) {

                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                
                if(response.status === 200){
                    console.log(response.data);
                    if(response.data.status < 0){
                        //handle JWT error
                        if(response.data.status == -100 || 
                            response.data.status == -101){
                            handleJWTError(response.data.status);
                        }
                        console.log(response.data.status);
                        props.showError(response.data.msg);
                        return;
                    }

                    if(response.data){                            
                        handleSuccess(response.data.account);
                        props.showError(null);
                    }
                } else{
                    props.showError("We are experiencing technical difficulties. Please try again later.");
                }
            })
            .catch(function (error) {
                console.log(error);
                setState(prevState => ({
                    ...prevState,
                    'loading' : false
                }));
                props.showError('We are experiencing technical difficulties. Please try again later.')    
            });    
        
    }
    
    const handleSuccess = (account) => {                
        localStorage.setItem('account', JSON.stringify(account));        
        setState(prevState => ({
            ...prevState,
            'completed' : true
        }))
    }

    const handleJWTError = (status) => {                
        props.history.push({
            pathname: '/uniwebview/jwt',
            search: '?status=' + status
          })        
    }

    const goBack = (e) => {
        props.history.push({
            pathname: '/app/account'
          })      
    }

  return(
        <div className="col-12 col-lg-4 login-card mt-2 hv-center">   
            {state.loading ? <LoadingSpinner /> : <div/> }                    

            <form className="form-horizontal">            
                <div className="form-group text-left mb-3">
                <label htmlFor="inputEmail1">Email</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Email"
                       value={state.email}
                       onChange={handleChange}
                       disabled 
                       readOnly
                />
               </div>
                 
                {!state.completed ?         
                    <div className="form-group text-left mb-3">
                    <label >How can we help?</label>
                    <textarea 
                        className="form-control" 
                        id="message" 
                        placeholder="Enter your question"
                        value={state.message}
                        onChange={handleChange}
                    />
                    </div> 
                : <div></div>}
                {!state.completed ?  
                    <div className="form-group text-left mb-3">
                    <button 
                        type="submit" 
                        className="btn btn-outline-primary"
                        onClick={handleSubmitClick}
                    >Send</button>
                    </div>  
                : <div></div>}   
                {state.completed ? 
                    <div className="form-group text-left">
                        <h6>Thank you for contacting us.</h6>
                        <h6>We will get back to you soon.</h6>
                    </div> 
                    : <div></div>}                                       
            </form>
        </div>
    )
}

export default withRouter(ContactUs);